<template>
	<div class="animated fadeIn">
		<div class="container">
			<user-data-form
				:form-import="form"
				:create-new-user="true"
				:errors-import="errors.errors"
				:statuses="statuses"
				:countries="countries"
				@requestData="requestNewUser" />
		</div>
	</div>
</template>
<script>
import User from '@/util/User';
import Country from '@/util/Country';
import { STATUSES as statuses } from '@/settings/Statuses';
import { Validations as messages } from '@/translations';
import { LOCKED } from '@/settings/Errors';
import UserDataForm from './components/UserDataForm';

export default {
	name: 'NewUser',
	messages,
	components: {
		UserDataForm,
	},
	data() {
		return {
			countryData: new Country(),
			user: new User(),
			statuses,
			form: {
				username: '',
				email: '',
				first_name: '',
				last_name: '',
				status: 'active',
				type: 'distributor',
				distributor_type: 'distributor',
				mobile_number: '',
				password: '',
				password_confirmation: '',
				sponsor_username: '',
				parent_username: null,
				leg: 'left',
				country: 'US',
				company: null,
				national_identification_number: null,
			},
			alert: new this.$Alert(),
		};
	},
	computed: {
		errors() {
			return this.user.data.errors;
		},
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				return response;
			} catch (error) {
				return [];
			}
		},
	},
	created() {
		this.countryData.getCountries();
	},
	mounted() {
		this.setFocus('username');
	},
	methods: {
		requestNewUser(payload) {
			this.user.createDistributor(payload).then((response) => {
				try {
					this.alert.toast('success', this.translate('user_created'));
				} finally {
					if (this.$can('distributors_information', 'view')) {
						this.$router.push({ name: 'DistributorsInformation', params: { distributorId: response.response.distributor_id } });
					} else {
						this.$router.push({ name: 'Users' });
					}
				}
			}).catch((errors) => {
				if (LOCKED.includes(errors.status)) {
					this.alert.toast('error', this.translate('invalid_position'));
				}
				this.data = payload;
			});
		},
	},
};
</script>
